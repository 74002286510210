import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/index/navbar"
import Layout from "../components/layout"
import Seo from "../components/SEO/seo"
import { Sidebar } from "../components/ui/sidebar"
import { collapseEvent } from "../helpers/collapseFuntion"
import { ListPriceButton } from "../helpers/ListPriceButton"
import "../styles/main.scss"

const UroAnalisis = () => {
  const { handleEventCollapse } = collapseEvent()

  const imageUrl = useStaticQuery(
    graphql`
      query {
        siteImg: file(
          relativePath: { eq: "Services/uroanalisis_43833726.jpeg" }
        ) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  )

  const { src: imgSource } = imageUrl.siteImg.childImageSharp.fluid //image

  const parametersSEO = {
    description:
      "Se trata del estudio que incluye una prueba de sangre en la orina, puede hacerse como parte de un chequeo de rutina o para detectar trastornos del tracto urinario, el riñón o el hígado.",
    meta: [],
    title: "Uroanalisís laboratorioclinico",
    imgSrc: imgSource,
    currentURL: "https://laboratorioclinico.sv",
    canonicalURL: "https://laboratorioclinico.sv",
    ogTitle: "Uroanalisís laboratorioclinico",
    keywords: [
      "prueba de embarazo en orina",
      "analisis de orina",
      "analisís de orina",
      "examen de orin",
      "examen general de orina",
      "examén general de orina",
    ],
  }

  return (
    <>
      <Layout>
        {/* HEADER CONTAINER */}
        <Seo {...parametersSEO} />
        <div className="banner-services__container">
          <svg
            width="1418"
            height="509"
            viewBox="0 0 1418 509"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1170.03 297.846L1121.05 337.513L1029.23 198.661C1024.68 191.802 1014.82 187.555 1003.41 187.555C994.136 187.555 982.379 190.604 977.179 199.205L897.062 332.178L786.458 14.2989C782.795 3.73804 769.442 0 759.162 0C747.168 0 733.874 4.7542 731.215 15.3877L634.969 399.39L537.008 146.509C533.05 136.275 519.815 132.646 509.653 132.646C499.786 132.646 487.556 135.985 482.888 145.312L397.04 317.806L333.821 225.988C329.212 219.274 319.345 215.137 308.061 215.137C298.962 215.137 290.69 217.895 285.313 222.722L211.046 288.229L205.905 290.333H29.5417C13.2347 290.333 0 298.463 0 308.479C0 318.496 13.2347 326.625 29.5417 326.625H223.571C232.552 326.625 241.06 323.939 246.377 319.149L302.388 270.046L375.652 376.345C380.319 383.131 389.891 387.196 401.235 387.196C413.701 387.196 424.277 382.188 428.118 374.494L506.344 217.278L613.521 494.147C617.539 504.418 630.183 508.083 640.7 508.083C652.694 508.083 665.987 503.329 668.646 492.66L763.475 114.319L862.321 398.483C865.748 408.354 878.687 412.818 889.913 412.818C901.848 412.818 911.538 408.463 915.969 401.168L1004.59 254.223L1088.85 381.607C1093.4 388.466 1103.32 392.712 1114.73 392.712C1123.71 392.712 1131.98 389.99 1137.3 385.236L1205 327.786L1210.14 326.117H1388.93C1405 326.117 1418 318.133 1418 308.262C1418 298.39 1405 290.333 1388.93 290.333H1192.66C1183.73 290.333 1175.29 293.128 1170.03 297.846V297.846Z"
              fill="white"
            />
          </svg>

          <div className="banner-services__container-fade-in"></div>
          <div className="banner-services__container-banner">
            {/* NAVBAR WITH PAGE TITLE */}
            <NavBar />
            <header className="banner-service">
              <div className="banner-service__button">
                <i className="fas fa-circle fa-xs"></i>
                <p>Servicios de Alta Calidad</p>
              </div>
              <h1 className="banner-service__title mt-2 mb-3">Uroanálisis</h1>
            </header>
          </div>
        </div>

        {/* MAIN CONTENT */}
        <div className="content-services__container">
          {/* MAIN CONTENT BACKGROUND */}
          <StaticImage
            src="../images/content.png"
            className="banner-content__background"
            placeholder="tracedSVG"
            width={1600}
            alt="background"
          ></StaticImage>

          {/* CONTENT WITH SIDEBAR */}
          <div className="content-services">
            <Sidebar />
            <div className="content-services__container">
              <div className="content-services__container-images">
                <StaticImage
                  src="../images/Services/uroanalisis_43833726.jpeg"
                  alt="uroanalisis_img"
                  placeholder="none"
                  layout="constrained"
                />
              </div>
              <div className="content-services__container-section1 mt-2">
                <h2 className="mb-1">¿Qué es Uroanálsis?</h2>
                <p>
                  Es una prueba de su orina. Se utiliza para detectar y tratar
                  una amplia gama de trastornos, como infecciones del tracto
                  urinario, enfermedades renales y diabetes. El análisis de
                  orina consiste en comprobar el aspecto, la concentración y el
                  contenido de la orina. Por ejemplo, una infección del tracto
                  urinario puede hacer que la orina tenga un aspecto turbio en
                  lugar de claro. El aumento de los niveles de proteínas en la
                  orina puede ser un signo de enfermedad renal. Los resultados
                  inusuales de los análisis de orina suelen requerir más pruebas
                  para encontrar el origen del problema.
                </p>
              </div>
              <div className="content-services__container-section2 mt-2">
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Examen de Orina
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Prueba de embarazo en orina
                  </li>
                </ul>
              </div>
              <div className="content-services__container-section3">
                <h2 className="mb-1 mt-2">¿Por qué se hace?</h2>
                <p>
                  Para comprobar su estado de salud general. Un análisis de
                  orina puede formar parte de un examen médico rutinario, una
                  revisión del embarazo o la preparación previa a una operación.
                  También puede utilizarse para detectar diversos trastornos,
                  como diabetes, enfermedades renales o hepáticas, cuando se
                  ingresa en un hospital.
                </p>
                <br />
                <p>
                  Para diagnosticar una enfermedad. Se puede solicitar un
                  análisis de orina si tiene dolor abdominal, dolor de espalda,
                  micción frecuente o dolorosa, sangre en la orina u otros
                  problemas urinarios. Un análisis de orina puede ayudar a
                  diagnosticar la causa de estos signos y síntomas.
                </p>
                <br />
                <p>
                  Para controlar una enfermedad. Si le han diagnosticado una
                  enfermedad, como una enfermedad renal o una infección del
                  tracto urinario, su médico puede recomendarle que analice su
                  orina con regularidad para controlar su estado y su
                  tratamiento.
                </p>
              </div>
              <ul className="content-services__container-section4 mt-2 mb-3">
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Qué es el examen general de orina?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Es una prueba muy común que puede realizarse en muchos
                    entornos sanitarios, como consultas médicas, centros de
                    atención urgente, laboratorios, hospitales e incluso en
                    casa. El análisis de orina se realiza recogiendo una muestra
                    de orina del paciente en un recipiente para muestras
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Cuándo hacer un examen general de orina?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    para comprobar su estado de salud general, todo el mundo
                    debería hacerse un análisis de orina cuando es niño y luego
                    periódicamente cuando es adulto. Algunos médicos utilizan el
                    análisis de orina como parte de un examen médico rutinario
                    para detectar los primeros signos de enfermedad, incluida la
                    enfermedad renal crónica.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿El examen general de orina debe de ser en ayunas?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    En general, no tiene que prepararse para un análisis de
                    orina. Puede comer y beber como lo haría normalmente. Sin
                    embargo, un análisis de orina se realiza a menudo con otras
                    pruebas que pueden requerir ayuno, así que consulte con su
                    proveedor de atención médica para verificar sus
                    instrucciones. Algunos medicamentos y suplementos pueden
                    afectar a los resultados de un análisis de orina.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ListPriceButton />
        <Footer />
      </Layout>
    </>
  )
}

export default UroAnalisis
